import React from 'react'
import VariantSelector from './VariantSelector'
import AddToCart from "../Cart/AddToCart"
import Styles from '../../templates/ProductDetailTemplate.module.css'

const ProductDetails = ({ product, ChangeVariantState, selectedVariant }) => {
    return (
        <div className={Styles.productDetails}>
            <h1 itemProp="name">{product.title}</h1>

            <p className={Styles.productdesc} itemProp="description">{product.description}</p>
            
            <div className={Styles.layer}>
                <p className={Styles.price} itemProp="offers" itemScope itemType="https://schema.org/Offer"><span itemProp="priceCurrency" content="USD">$</span><span itemProp="price" content={selectedVariant.price}>{selectedVariant.price}</span></p>
            </div>

            <div className={Styles.buystuff}>

                <AddToCart variantId={selectedVariant.shopifyId} />
            </div>

        </div>
    )
}

export default ProductDetails
