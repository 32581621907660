import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Styles from './ProductDetailTemplate.module.css'
import { Helmet } from "react-helmet"
import Relatedproducts from '../components/relatedproducts/relatedproducts'
import ProductImages from '../components/productDetails/ProductGallery'
import ProductDetails from '../components/productDetails/ProductDetails'
import {FaChargingStation, FaFeatherAlt, FaHammer ,FaHandHoldingHeart} from 'react-icons/fa'
const ProductDetailTemplate = ({ data }) => {

  const { shopifyProduct: product } = data
  const { allShopifyCollection: collection } = data
  const [selectedVariant, setVariant] = useState(product.variants[0])


  let productcategory;
  let categoryName;
  let categoryHandle;

  productType();

  //gets product type for related product componenet
  function productType() {
    productcategory = product.productType;

    collection.nodes.map(node =>{
      if(node.title.includes(productcategory)){
        categoryName = node.title;
        categoryHandle = node.handle;
      }
    });
  }

  //gets the selected product variant
  function ChangeVariantState(e) {
    let selected;

    product.variants.forEach(variant => {
      if (variant.sku === e.target.value) {
        selected = variant;
      }
    });
    setVariant(selected)
  }




  return (
    <Layout>

      <Helmet>
        <title>{product.title}</title>
        <meta name="description" content={product.description} />
        <meta name="keywords" content={product.tags} />
        <meta name="og:title" content={product.title} />
        <meta property="og:type" content="product" />
        <meta property="og:image" content="../images/CustomogThumbnail.png" />
      </Helmet>

      <div className={Styles.mainDiv}>
        <div className={Styles.breadcrumb}> <Link className={Styles.breadcrumbLink} to={'/' + categoryHandle} replace><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left"  role="img" xmlns="http://www.w3.org/2000/svg" width="20px" viewBox="0 0 445 512"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg> <span className={Styles.breadcrumbText}>Back To {categoryName}</span></Link> </div>
        <div className={Styles.wrapper} itemScope itemType="https://schema.org/Product">
          <ProductImages product={product} />
          <ProductDetails product={product} ChangeVariantState={ChangeVariantState} selectedVariant={selectedVariant} />
        </div>
        <div className={Styles.careContainer}>
            <div className={Styles.careWrapper}>
            <FaHandHoldingHeart size={70}/>
            
            </div>
            <div className={Styles.listinfowords}>
            <b>Care Instructions</b>
            <p>Clean with a damp cotton or microfiber cloth. Add a drop of dish soap to the cloth if needed and wipe the soap down with a clean, damp cloth.</p>
            </div>
        </div>

        <div className={Styles.aboutWrapper}>
        <h4 className={Styles.caseinfotitle}>About Our Cases</h4>
           
        <ul className={Styles.productlist}>
            <li>
                <div className={Styles.listinfothings}>
                <div>
                    <FaChargingStation size={70}/>
                </div>
                <div className={Styles.listinfowords}>
                    <b>Supports wireless charging</b>
                    <p>Our cases are compatible with all kinds of wireless charging devices!</p>
                </div>
                </div>
            </li>
            <li>
                <div className={Styles.listinfothings}>
                <div>
                    <FaFeatherAlt size={70}/>
                </div>
                <div className={Styles.listinfowords}>
                    <b>Lightweight and Thin</b>
                    <p>Our cases are thin and Lightweight, not boxy, not clunky. The case fits snug around your phone and keeps a slim profile, fitting around the curves of your phone.</p>
                </div>
                </div>
            </li>

            <li>
                <div className={Styles.listinfothings}>
                <div>
                    <FaHammer size={70}/>
                </div>
                <div className={Styles.listinfowords}>
                    <b>Durable</b>
                    <p>While are cases are thin, they are not fragile. They are made from a strong, durable and impact resistant Polycarbonate material.</p>
                </div>
                </div>
            </li>
            </ul>
            </div>
        <h4 className={Styles.recommenedtitle}>You May Also Like</h4>
        <div className={Styles.recommenedwrapper}>
          <Relatedproducts Cat={productcategory} Title={product.title}/>
        </div>

      </div>


    </Layout >
  )
}

export default ProductDetailTemplate


export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id 
      handle
      title
      productType
      description
      variants {
        id
        shopifyId
        title
        price
        sku
        availableForSale
      }
      tags
      images {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allShopifyCollection {
      nodes {
        handle
        title
       
      }
    }
  }
`






