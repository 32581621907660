import React from "react"
import Styles from './relatedproducts.module.css'
import { graphql, useStaticQuery } from "gatsby"
import ProductsListingItem from '../ProductCard/ProductsListingItem'

const RelatedProducts = React.memo(props => {

  const query2 = useStaticQuery(graphql`
    query{
    allShopifyCollection {
      nodes {
        handle
        title
        products{
          title
          id
          handle
          description
          productType
          variants {
            shopifyId
            title
            price
            availableForSale
          }
          images {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    }
    `
  )



  function shuffleArray(array) {

    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;

  }


  return (
    <div className={Styles.recommenedcontainer}>
      {query2.allShopifyCollection.nodes.map(node => (
        node.title == (props.Cat + ' Cases') && shuffleArray(node.products).slice(0, 4).map(
          rproduct => (
            rproduct.title === props.Title ? (
              false
            ) : (
                <ProductsListingItem key={rproduct.id} product={rproduct} />
              )
          )
        ))

      )
      }
          {query2.allShopifyCollection.nodes.map(node => (
         node.title == (props.Cat + 's')  && shuffleArray(node.products).slice(0, 4).map(
          rproduct => (
            rproduct.title === props.Title ? (
              false
            ) : (
                <ProductsListingItem key={rproduct.id} product={rproduct} />
              )
          )
        ))

      )
      }
          {query2.allShopifyCollection.nodes.map(node => (
       node.title == ('Samsung Galaxy ' + props.Cat + ' Cases')   && shuffleArray(node.products).slice(0, 4).map(
          rproduct => (
            rproduct.title === props.Title ? (
              false
            ) : (
                <ProductsListingItem key={rproduct.id} product={rproduct} />
              )
          )
        ))

      )
      }
          {query2.allShopifyCollection.nodes.map(node => (
       node.title == ('Samsung Galaxy ' + props.Cat + 's') && shuffleArray(node.products).slice(0, 4).map(
          rproduct => (
            rproduct.title === props.Title ? (
              false
            ) : (
                <ProductsListingItem key={rproduct.id} product={rproduct} />
              )
          )
        ))

      )
      }
    </div>
  )
})

export default RelatedProducts
