import React, { useContext } from "react"
import { StoreContext } from "../../context/StoreContext"
import Styles from './css/AddToCart.module.css';

const AddToCart = ({ variantId }) => {
  const { addProductToCart } = useContext(StoreContext)

  return (
    <button
      className={Styles.button}
      onClick={() => addProductToCart(variantId)}
    >
      Add To Cart
    </button>
  )
}

export default AddToCart
