import React, { useState } from "react"
import Styles from '../../templates/ProductDetailTemplate.module.css'
import Image from "gatsby-image"

const ProductGallery = ({ product }) => {

    const [i, setI] = useState(0);

    return (
        <div className={Styles.imagecomponent}>
            {/* Main Product Image */}
            <div className={Styles.imagecontainer}>
                <Image itemProp="image" fluid={product.images[i].localFile.childImageSharp.fluid} className={Styles.imageliteral} />
            </div>

            {/* Loops thourgh variant images for the image galary */}
            <div className={Styles.imagevariantscontainer}>
                {
                    product.images.map((image, index) => {
                        return (
                            <button className={Styles.smallimagebox} onClick={function h() { setI(index) }}>
                                <Image itemProp="image" fluid={image.localFile.childImageSharp.fluid} className={Styles.smallimageliteral} />
                            </button>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default ProductGallery
